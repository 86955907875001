<template>
    <div class="bg-gray-50 dark:bg-gray-800 py-16 sm:py-24">
        <div class="mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8">
            <h2 class="text-center text-base/7 font-semibold text-indigo-400">Join the Community of over {{ siteCount }}
                online stores!</h2>
            <p class="mx-auto mt-2 max-w-lg text-balance text-center text-4xl font-extrabold tracking-tight text-gray-800 dark:text-white sm:text-5xl">
                Why list your store on TCGCompare?
            </p>
            <p class="mt-6 max-w-lg mx-auto text-center text-xl text-gray-400 sm:max-w-3xl">
                Empower your store to compete effectively, boost visibility, and reach dedicated trading card
                enthusiasts from {{ countryCount }} different countries around the world.
            </p>
            <div class="mt-10 grid gap-4 sm:mt-16 lg:grid-cols-3 lg:grid-rows-2">
                <div class="relative lg:row-span-2">
                    <div class="absolute inset-px rounded-lg bg-white dark:bg-gray-900 lg:rounded-l-[2rem]"/>
                    <div
                        class="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] lg:rounded-l-[calc(2rem+1px)]">
                        <div class="px-8 pb-3 pt-8 sm:px-10 sm:pb-0 sm:pt-10">
                            <p class="mt-2 text-lg font-medium tracking-tight text-gray-950 dark:text-gray-200 max-lg:text-center">
                                Increase Store Visibility</p>
                            <p class="mt-2 max-w-lg text-sm/6 text-gray-600 dark:text-gray-400 max-lg:text-center">With
                                TCGCompare, your listings will be highlighted across our user base, ensuring more eyes
                                see your store.</p>
                        </div>
                        <div
                            class="relative min-h-[30rem] w-full grow [container-type:inline-size] max-lg:mx-auto max-lg:max-w-sm">
                            <div
                                class="absolute inset-x-10 bottom-0 top-10 overflow-hidden rounded-t-[12cqw] border-x-[3cqw] border-t-[3cqw] border-gray-700 bg-gray-900 shadow-2xl">
                                <img class="size-full object-cover object-top" :src="listings" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div
                        class="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-l-[2rem]"/>
                </div>
                <div class="relative max-lg:row-start-1">
                    <div class="absolute inset-px rounded-lg bg-white dark:bg-gray-900 max-lg:rounded-t-[2rem]"/>
                    <div
                        class="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-t-[calc(2rem+1px)]">
                        <div class="px-8 pt-8 sm:px-10 sm:pt-10">
                            <p class="mt-2 text-lg font-medium tracking-tight text-gray-950 dark:text-gray-200 max-lg:text-center">
                                Drive Ready Buyers</p>
                            <p class="mt-2 max-w-lg text-sm/6 text-gray-600 dark:text-gray-400 max-lg:text-center">Bring
                                motivated buyers directly to your store, ready to make purchases for their
                                collections.</p>
                        </div>
                        <div
                            class="flex flex-1 items-center justify-center px-8 max-lg:pb-12 max-lg:pt-10 sm:px-10 lg:pb-2">
                            <img class="w-full max-lg:max-w-xs rounded-lg" :src="purchase" alt=""/>
                        </div>
                    </div>
                    <div
                        class="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-t-[2rem]"/>
                </div>
                <div class="relative max-lg:row-start-3 lg:col-start-2 lg:row-start-2">
                    <div class="absolute inset-px rounded-lg bg-white dark:bg-gray-900"/>
                    <div
                        class="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)]">
                        <div class="px-8 pb-3 pt-4 sm:px-10 sm:pb-0 sm:pt-10">
                            <p class="mt-2 text-lg font-medium tracking-tight text-gray-950 dark:text-gray-200 max-lg:text-center">
                                Build Trust</p>
                            <p class="mt-2 max-w-lg text-sm/6 text-gray-600 dark:text-gray-400 max-lg:text-center">Build
                                trust with customers through our verified seller program</p>
                        </div>
                        <div
                            class="flex flex-1 items-center justify-center px-8 max-lg:pb-12 max-lg:pt-10 sm:px-10 lg:pb-2">
                            <img class="h-[min(152px,40cqw)] object-cover rounded-lg" :src="trust" alt=""/>
                        </div>
                    </div>
                    <div class="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5"/>
                </div>
                <div class="relative lg:row-span-2">
                    <div
                        class="absolute inset-px rounded-lg bg-white dark:bg-gray-900 max-lg:rounded-b-[2rem] lg:rounded-r-[2rem]"/>
                    <div
                        class="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-b-[calc(2rem+1px)] lg:rounded-r-[calc(2rem+1px)]">
                        <div class="px-8 pt-4 sm:px-10 sm:pt-10">
                            <p class="mt-2 text-lg font-medium tracking-tight text-gray-950 dark:text-gray-200 max-lg:text-center">
                                Rise Above The Rest</p>
                            <p class="mt-2 max-w-lg text-sm/6 text-gray-600 dark:text-gray-400 max-lg:text-center">Stand
                                out from competitors and convince buyers to choose your store with unique discount
                                codes.</p>
                        </div>
                        <div
                            class="relative min-h-[30rem] w-full grow [container-type:inline-size] max-lg:mx-auto max-lg:max-w-sm">
                            <div
                                class="absolute inset-x-10 bottom-0 top-10 overflow-hidden rounded-t-[12cqw] border-x-[3cqw] border-t-[3cqw] border-gray-700 bg-gray-900 shadow-2xl">
                                <img class="size-full object-cover object-top" :src="discounts" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div
                        class="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 max-lg:rounded-b-[2rem] lg:rounded-r-[2rem]"/>
                </div>
            </div>
            <p class="mt-6 max-w-lg mx-auto text-center text-xl text-gray-400 sm:max-w-3xl">
                TCGCompare is an entirely free service, and we do not charge any fees for listing your store or products. In order to keep services free, we'd love to know if you have an affiliate program.
                <br /> <br />
                Get in touch with us to learn more about listing your store on TCGCompare.
            </p>
            <div class="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                <a
                    href="https://linktr.ee/tcgcompare"
                    title="Visit our Linktree"
                    target="_blank"
                    class="flex items-center justify-center px-4 py-3 text-white bg-indigo-500 hover:bg-indigo-400 rounded-md"
                >

                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                         xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 80 97.7"
                         style="enable-background:new 0 0 80 97.7;" xml:space="preserve" class="w-6 h-6 ms-2 text-white mr-2">
                        <g>
                          <path class="text-white fill-white" d="M0.2,33.1h24.2L7.1,16.7l9.5-9.6L33,23.8V0h14.2v23.8L63.6,7.1l9.5,9.6L55.8,33H80v13.5H55.7l17.3,16.7
                                l-9.5,9.4L40,49.1L16.5,72.7L7,63.2l17.3-16.7H0V33.1H0.2z M33.1,65.8h14.2v32H33.1V65.8z">
                          </path>
                         </g>
                        </svg>
                    Linktree
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import logo from "/logo.svg";
import homebackdrop from "/backdrop.webp";
import listings from "/screenshots/listings.png";
import discounts from "/screenshots/discount.png";
import purchase from "/screenshots/purchase.png";
import trust from "/screenshots/trust.png";
import useTitleManager from "../../../utils/useTitleManager";
import {onBeforeMount, defineComponent, ref} from "vue";
import useMiscInformationStore from "../../../utils/useMiscInformationStore";

const navigation = [
    {name: 'Product', href: '#'},
    {name: 'Features', href: '#'},
    {name: 'Marketplace', href: '#'},
    {name: 'Company', href: '#'},
]

export default defineComponent({
    name: "StoreLandingPage",
    components: {},
    setup() {
        const {setTitlePrefix} = useTitleManager();
        const miscInformationStore = useMiscInformationStore();

        const siteCount = ref(0);
        const countryCount = ref(0);

        onBeforeMount(async () => {
            setTitlePrefix("Store Information");
            await miscInformationStore.loadStats();
            siteCount.value = miscInformationStore.stats.total_stores ? Math.floor(miscInformationStore.stats.total_stores.value / 100) * 100 : 0;
            countryCount.value = miscInformationStore.stats.countries ? miscInformationStore.stats.countries.value : 0;
        })

        return {
            logo,
            homebackdrop,
            navigation,
            pageUrl: window.location.href,
            logoUrl: window.location.href + "logo.svg",
            siteCount,
            countryCount,
            listings,
            discounts,
            purchase,
            trust
        }
    },
});
</script>