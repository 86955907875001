<template>
    <teleport to="head">
        <link rel="canonical" :href="pageUrl" />
        <meta property="og:title" content="Leaving TCGCompare..." />
        <meta property="og:description" content="Hold tight, I'm redirecting you now..." />
        <meta property="og:image" :content="logo" />
    </teleport>
    <div class="relative w-screen min-h-screen bg-gradient-to-t from-indigo-800 to-gray-900 mix-blend-multiply scrollbar-thin scrollbar-thumb-indigo-700">
        <div class="mx-auto max-w-7xl py-4 sm:px-6 sm:py-20 lg:px-8">
            <div class="relative isolate overflow-hidden bg-gray-900 px-6 py-4 text-center shadow-2xl sm:rounded-3xl sm:px-16">

                <div class="flex items-center justify-center mb-10">
                    <img class="h-64 w-auto" :src="logo" alt="Image of Logo"/>
                </div>
                <h2 class="text-balance text-4xl font-semibold tracking-tight text-white sm:text-5xl">Hold tight, I'm redirecting you now...</h2>
                <div class='flex flex-row justify-center items-center gap-3 mt-8'>
                    <span class='sr-only'>Loading...</span>
                    <div class='h-8 w-8 bg-indigo-700 rounded-full animate-bounce [animation-delay:-0.3s]'></div>
                    <div class='h-8 w-8 bg-indigo-700 rounded-full animate-bounce [animation-delay:-0.15s]'></div>
                    <div class='h-8 w-8 bg-indigo-700 rounded-full animate-bounce'></div>
                </div>
                <ErrorBanner class="mt-10" message="There was an error while trying to redirect you. Click the button below." :show="showError" />

                <p class="mx-auto mt-6 max-w-xl text-pretty text-lg/8 text-gray-300">If, for whatever reason the stock, price, or link isn't correct, here's a link to other possible listings to try:</p>
                <div class="mt-10 flex items-center justify-center gap-x-6">
                    <a :href="productUrl" class="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm bg-indigo-900 text-white hover:bg-indigo-800 sm:px-8">{{
                            productName
                        }}</a>
                </div>

                <svg viewBox="0 0 1024 1024" class="absolute left-1/2 top-1/2 -z-10 size-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]" aria-hidden="true">
                    <circle cx="512" cy="512" r="512" fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)" fill-opacity="0.7" />
                    <defs>
                        <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
                            <stop stop-color="#302e80" />
                            <stop offset="1" stop-color="#302e80" />
                        </radialGradient>
                    </defs>
                </svg>
            </div>
        </div>
    </div>

</template>
<script lang="ts">
import {useRoute, useRouter} from "vue-router";
import {computed, onBeforeMount, ref} from "vue";
import logo from "/logo.svg";
import homebackdrop from "/backdrop.webp";
import useProductStore from "../../products/useProductStore";
import useLocalStorageStore from "../../../utils/useLocalStorageStore";
import productApiClient from "../../../api/ProductApiClient";
import ErrorBanner from "../../../components/ErrorBanner.vue";
import useTitleManager from "../../../utils/useTitleManager";


export default {
    components: {ErrorBanner},
    setup(){

        const route = useRoute();
        const productStore = useProductStore();
        const productName = ref("View Other Listings");
        const product = computed(() => productStore.activeProduct);
        const productUrl = ref("/");
        const showError = ref(false);
        const { setTitlePrefix } = useTitleManager();

        const setup = async () => {
            productUrl.value = `${window.location.origin}/${route.params.game}/${route.params.categorySlug}/${route.params.productSlug}`;
            try{
                await productStore.clearActiveProduct();
                await productStore.getProduct(route.params.game, route.params.categorySlug, route.params.productSlug);
                await productStore.getProductPrice(route.params.game, route.params.categorySlug, route.params.productSlug, route.params.productPriceId);
            } catch(e){
                console.error(e);
                showError.value = true;
            }

            productName.value = product.value?.name || "Loading...";
            await logRequest();
        }
        const logRequest = async () => {
            try{
                await productApiClient.productOut(route.params.game, product.value.category.slug, product.value.slug, route.params.productPriceId, productStore.activeProductPrice.site.slug)
            } catch(e){
                console.error(e);
            }
            await redirectToSite();
        }

        const redirectToSite = async () => {
            if(productStore.activeProductPrice?.url){
                window.location.href = productStore.activeProductPrice.atc;
            } else {
                showError.value = true;
            }
        }

        onBeforeMount(async() => {
            setTitlePrefix("Leaving TCGCompare...");
            await setup();
        })

        return {
            logo,
            homebackdrop,
            showError,
            productName,
            pageUrl: window.location.href,
            productUrl
        }
    }
}
</script>
